<template>
  <TemporaryLanding />
  <div class="decorative-line-container">
    <div class="decorative-line"></div>
  </div>
  <a href="https://isolationtankensemble1.bandcamp.com/album/heliography" class="store-link">

  </a>
  <div class="main-graphic">
      <FeaturedContent />
  </div>
</template>

<script>
import TemporaryLanding from './components/TemporaryLanding.vue';
import FeaturedContent from './components/FeaturedContent.vue';
// import BandContentComponent from './components/BandContentComponent.vue';
export default {
  name: 'App',
  components: {
    TemporaryLanding,
    FeaturedContent,
    // BandContentComponent,
}
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: black;
  height: 100vh;
}

.band-logo {
    width: 40%;
    margin: auto;
    z-index: auto;
}

.decorative-line {
  /* border-bottom: 4px solid #E93B12; */
  position: absolute;
  top: 50px;
  width: 100%;
  z-index: 1;
}

.decorative-line-container {
  position: relative;
}

.logo {
    width: 100%;
    margin: auto;
}

.store-link {
    text-decoration: none;
    color: black;
}


.align-left {
  margin-left: 0;
}

.main-graphic {
  background: url('../public/desert-landscape.png');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;

  height: 80vh;
  width: 100%;
}
</style>
