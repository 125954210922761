<template>
    <div class="featured-content">
        <div class="album-title-image">
            <img src="../../public/heliography-title.png" class="album-title">
            <div class="tagline-container">
                <h3>An Instrumental Story of Adventurers Between Worlds</h3>
                <h3 class="subtitle">Brought to you by the:</h3>
                <a href="https://linktr.ee/isolationtankensemble" target="_blank">                <img src="../../public/iso-logo.png" class="iso-logo"></a>
                <div class="flex-for-cutout-words">
                    <h3 class="subtitle2 cut-out-words">a</h3>
                    <h3 class="subtitle2 cut-out-words">cinematic</h3>
                    <h3 class="subtitle2 cut-out-words">trash</h3>
                    <h3 class="subtitle2 cut-out-words">prog</h3>
                    <h3 class="subtitle2 cut-out-words">six</h3>
                    <h3 class="subtitle2 cut-out-words">piece</h3>
                </div>
            </div>
            <div class="container">
                <img src="../../public/heliography-cover.png" class="album-photo">
<!-- button content here -->
                <a href="https://chaoticgoodrecords.square.site/" class="store-link">
                    <div class="button-buy-now">
                        Buy 2LP Vinyl now!
                    </div>
                </a>
            </div>
        </div>
    </div>
    <div class="copyright-container">
        <span class="copyright">Website property of Chaotic Good Records est. 2023&copy;</span>
    </div>
</template>

<script>
// import BandContentComponent from './BandContentComponent.vue';

export default {
    name: 'FeaturedContent',
    // components: { BandContentComponent }
}
</script>

<style scoped>

.album-photo {
    width: 75%;
    height: auto;
    background-color: white;
    padding: 10px;
}
.album-title {
    width: 100%;
    height: auto;
}

.iso-logo {
    height: 120px;
    width: auto;
    margin-top: -20px;
}



.button-buy-now {
    border: 6px solid #3af0c2;
    background-color: white;
    width: 25%;
    font-family: 'Special Elite', cursive;
    font-size: 18px;

    position: absolute;
    top: 10%;
    left: 80%;
    padding: 8px;

}

.button-buy-now:hover {
    background-color: #DA2B53;
    color: white;
}
.container {
    position: relative;
    width: 75%;
    margin: auto;
}
.copyright {
  font-family: 'Special Elite', cursive;
  font-size: 12px;
  padding: 10px;
}
.feautured-content {
    width: 100%;
}
.tagline-container {
    font-family: 'Montserrat', sans-serif;
    font-size: 14px;
}

.store-link {
    text-decoration: none;
    color: black;
}

.subtitle {
    font-size: 12px;
    font-family: 'Special Elite', cursive;
    border-top: 2px solid #C98C2B;
    padding: 10px;
    width: 40%;
    margin: auto;
    letter-spacing: .5px;
}

.subtitle2 {
    font-size: 12px;
    font-family: 'Special Elite', cursive;
    margin: auto;
    letter-spacing: .5px;
}
.cut-out-words {
    background: white;
    color: black;
    padding: 5px;
    box-shadow: 1px 1px 1px black;
}

.flex-for-cutout-words {
    display: flex;
    flex-direction: row;
    justify-items: center;
    padding-top: -10px;
    padding-bottom: 10px;
    width: 60%;
    margin: auto;
    height: auto;
}
</style>