<template>
  <div class="construction-page">
    <div class="stamp">
      <h2 class="cut-out-effect">Chaotic</h2>
      <h2 class="cut-out-effect middle-text">Good</h2>
      <h2 class="cut-out-effect">Records</h2>
    </div>
  </div>
<!-- featuring: here -->
  <div class="featuring">
    Presents to the World: 
  </div>
</template>

<script>
export default {
  name: 'HelloWorld',
  props: {
    msg: String
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

h2, h4 {
  padding: 0;
  margin: 10px;
}

.construction-page {
  display: flex;
  flex-direction: column;
}

.cut-out-effect {
  padding: 4px;
  margin: auto;
}

.featuring {
  font-family: 'Special Elite', cursive;
  font-size: 25px;
  font-weight: bolder;
  text-transform: uppercase;
  padding: 10px;
  margin: 20px;
}
.message {
  font-family: 'Special Elite', cursive;
  letter-spacing: 2px;
}
.middle-text {
  font-size: 60px;
}

.stamp {
  display: flex;
  flex-direction: column;
  justify-content: center;
  

  font-family: 'Special Elite', cursive;
  font-size: 18px;
  text-transform: uppercase;

  border: 4px solid rgba(0, 0, 0, 0.798);
  border-radius: 10px;
  width: auto;
  margin: auto;
  padding: 10px;
}

</style>
